@import url("https://use.typekit.net/ecu8mso.css");

body {
    font-family: azo-sans-web, sans-serif;
    font-weight: 300; /*light*/
    font-size: 18px;
    line-height: 1.2;
    color: #000;
}
audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
    max-width: 100%;
}
img {
    height:auto !important;
}
hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #000;
    margin: 10px 0;
    padding: 0;
}
h1, h2,
.highlight {
    font-family: garamond-premier-pro-display, serif;
    font-weight: 300; /*light*/
    font-style: normal;
}
h1, h2 {
    font-size: 3.8em;
}
h3 {
    font-family: garamond-premier-pro-display, serif;
    font-weight: 300; /*light*/
    font-size: 2em;
    margin-bottom: 1.5em;
}
h4 {
    text-transform: uppercase;
    font-weight: 400; /*regular*/
    margin-bottom: 0px;
}

.clear-float {
    clear:both;
}
.highlight {
    display: inline-block;
    font-size: 2em;
    padding: 30px;
    width: 100%;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    box-sizing: border-box;
    margin: 30px 0px;
    margin-bottom: 40px;
}
.small {
    font-size: 14px;
}
.button,
form button {
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    font-size: 16px;
    font-weight: 500; /*medium*/
    text-transform: uppercase;
    line-height: 1;
    text-decoration: none;
    padding: 10px;
    padding-right: 20px;
    max-width: 100%;
    color: #000;
    background-color: transparent;
    border: 1px solid #000;
    box-sizing: border-box;
    transition: all 0.3s ease;
}
.button:hover,
form button:hover {
    color: #FFF;
    background-color: #000;
}

img.display-left {
    float:left;
    max-width: 50%;
    margin-right:20px;
    margin-bottom:10px;
}
img.display-right {
    float:right;
    max-width: 50%;
    margin-left:20px;
    margin-bottom:10px;
}

.two-column {
    display: inline-block;
    vertical-align: top;
    width: 48%;
    padding: 1%;
    box-sizing: border-box;
}

@media only screen and (max-width: 760px){
/* reduce font-size */
    h1, h2 {
        font-size: 2em;
    }
    h3,
    .highlight {
        font-size: 1.5em;
    }
/** editor styles */
    img.display-left,
    img.display-right {
        float: none;
        max-width: 100%;
        margin: 20px 0;
    }
    .two-column {
        width: 100%;
        padding: 0px;
    }

}